import classNames from 'classnames';
import React, { useCallback, useEffect, useId, useRef, useState } from 'react';

import headerLogo from '@/assets/images/header-logo-new.svg';
import { Button } from '@/design-system/atoms/Button';
import { Typeahead } from '@/design-system/atoms/Forms/Typeahead';
import { LazyIconArrowLeft } from '@/design-system/atoms/Icons/IconArrowLeft/Lazy';
import { LazyIconChevronRight } from '@/design-system/atoms/Icons/IconChevronRight/Lazy';
import { LazyIconMenu2 } from '@/design-system/atoms/Icons/IconMenu2/Lazy';
import { LazyIconSearch } from '@/design-system/atoms/Icons/IconSearch/Lazy';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { trackInteraction } from '@/utils/analytics';
import { scrollControl } from '@/utils/scrollControl';
import window from '@/utils/window';

import { useHeaderContext } from '../../contexts/HeaderContext';
import { headerPropTypes } from '../../headerPropTypes';
import styles from './MobileHeader.module.scss';

const MobileHeader = ({
    logoHref,
    search,
    searchLabelOverride = 'Search',
    searchAriaLabel = 'Open Global Site Search',
    closeSearchAriaLabel = 'Close Global Site Search',
    mainNavAriaLabel = 'Open Main Navigation',
    closeNavAriaLabel = 'Close Main Navigation',
    backNavAriaLabel = 'Back to Main Navigation',
    navigationItems,
    primaryCta,
    utilityNavigationItems,
    coveo,
    searchResultsPage,
    disableCSR,
    hideLanguageSelector,
    onLanguageChange,
    isActive,
    ...props
}) => {
    const key = useId();
    const navigationItemsKey = useId();
    const utilityItemsKey = useId();
    const utilityChildKey = useId();
    const levelTwoItemKey = useId();
    const mobileNavId = useId();

    const headerRef = useRef();
    const openMenuButtonRef = useRef();
    const closeMenuButtonRef = useRef();
    const mobileNavRef = useRef();
    const backButtonRef = useRef();
    const levelOneRef = useRef();
    const languageDropdownRef = useRef();
    const featureCardRef = useRef();
    const searchButtonRef = useRef();
    const searchBarRef = useRef();
    const searchInputRef = useRef();
    const menuSearchButtonRef = useRef();
    const languageRefs = useRef([]);

    const [url, setUrl] = useState('');
    const [urlPathname, setUrlPathname] = useState('');
    const [urlSearch, setUrlSearch] = useState('');

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentNavigationIndex, setCurrentNavigationIndex] = useState(null);
    const [levelTwoOpen, setLevelTwoOpen] = useState(false);
    const [parentNavItem, setParentNavItem] = useState(null);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [levelTwoItems, setLevelTwoItems] = useState(null);
    const [searchBarOpen, setSearchBarOpen] = useState(false);

    const [languageLabel, setLanguageLabel] = useState('English');

    const { searchValue, suggestions, handleSetSearchValue, handleSelectSuggestion, submitForm } =
        useHeaderContext();

    useEffect(() => {
        const headerParent = headerRef?.current?.parentElement;
        let pageChildren = [];

        for (const child of headerParent.children) {
            if (child.tagName !== 'HEADER') {
                pageChildren.push(child);
            }
        }

        if (isMenuOpen || searchBarOpen) {
            pageChildren.forEach((child) => {
                child.setAttribute('inert', '');
            });
        } else {
            pageChildren.forEach((child) => {
                child.removeAttribute('inert');
            });
        }
    }, [headerRef, isMenuOpen, searchBarOpen]);

    const ToggleSearchBar = useCallback(() => {
        if (searchBarOpen) {
            setSearchBarOpen(false);
            scrollControl.enableScroll();
            if (isMenuOpen) {
                mobileNavRef.current.removeAttribute('inert');
                setTimeout(() => {
                    menuSearchButtonRef.current.focus();
                }, 0);
            } else {
                setTimeout(() => {
                    searchButtonRef.current.focus();
                }, 0);
            }

            // Analytics
            trackInteraction({
                eventName: 'search',
                componentName: 'Navigation',
                componentTitle: 'navigation',
                interactionType: 'close',
                selector: 'search',
                searchTerm: searchValue,
            });
        } else {
            setSearchBarOpen(true);
            scrollControl.disableScroll();
            if (isMenuOpen) {
                mobileNavRef.current.setAttribute('inert', '');
            }
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 0);
        }
    }, [searchBarOpen, searchButtonRef, searchInputRef, searchValue, isMenuOpen, mobileNavRef]);

    const OpenLevelTwo = (e, index) => {
        levelOneRef.current.setAttribute('inert', '');
        setCurrentNavigationIndex(index);
        setLevelTwoItems(navigationItems[index]);
        setLevelTwoOpen(true);
        setParentNavItem(e.target);
        if (languageDropdownOpen) {
            setLanguageDropdownOpen(false);
            languageDropdownRef.current.setAttribute('inert', '');
        }
        setTimeout(() => {
            backButtonRef.current.focus();
        }, 250);
    };

    const CloseLevelTwo = () => {
        levelOneRef.current.removeAttribute('inert');
        setLevelTwoOpen(false);

        setTimeout(() => {
            //settimeout here is to ensure the inert attribute is removed before focusing on the parent nav item
            parentNavItem.focus();
        }, 0);

        setTimeout(() => {
            setCurrentNavigationIndex(null);
            setParentNavItem(null);
        }, 350);
    };

    const OpenMenu = () => {
        setIsMenuOpen(true);
        scrollControl.disableScroll();
        languageDropdownRef?.current?.setAttribute('inert', '');
        setTimeout(() => {
            closeMenuButtonRef.current?.focus();
        }, 250);
    };

    const CloseMenu = useCallback(
        (skipFocus) => {
            levelOneRef.current.removeAttribute('inert');
            setIsMenuOpen(false);
            scrollControl.enableScroll();
            setCurrentNavigationIndex(null);
            if (languageDropdownOpen) {
                setLanguageDropdownOpen(false);
                languageDropdownRef?.current?.setAttribute('inert', '');
            }
            if (!skipFocus) {
                setTimeout(() => {
                    openMenuButtonRef?.current?.focus();
                }, 250);
            }
        },
        [
            levelOneRef,
            languageDropdownOpen,
            setIsMenuOpen,
            setCurrentNavigationIndex,
            setLanguageDropdownOpen,
            openMenuButtonRef,
        ],
    );

    const ToggleDropdown = useCallback(() => {
        if (languageDropdownOpen) {
            setLanguageDropdownOpen(false);
            languageDropdownRef?.current?.setAttribute('inert', '');
        } else {
            languageDropdownRef?.current?.removeAttribute('inert');
            setLanguageDropdownOpen(true);
        }
    }, [languageDropdownOpen, languageDropdownRef, setLanguageDropdownOpen]);

    const handleLogoClick = useCallback(() => {
        trackInteraction({
            componentName: 'Navigation',
            componentTitle: 'navigation',
            interactionType: 'nav_logo',
            actionLabel: 'Atlantic Health System Homepage',
        });
    }, []);

    const resetHeader = useCallback(() => {
        CloseMenu(true);
        if (languageDropdownOpen) {
            ToggleDropdown();
        }

        if (searchBarOpen) {
            ToggleSearchBar();
        }
    }, [CloseMenu, ToggleDropdown, languageDropdownOpen, searchBarOpen, ToggleSearchBar]);

    useEffect(() => {
        const featureHeight = featureCardRef.current?.getBoundingClientRect().height;
        setScrollHeight(featureHeight);
    }, [currentNavigationIndex]);

    useEffect(() => {
        languageRefs?.current.forEach((ref) => {
            if (ref?.getAttribute('active-language') > -1) {
                setLanguageLabel(ref.getAttribute('label-setter'));
            }
        });
    }, []);

    useEffect(() => {
        if (!isActive) {
            resetHeader();
        }
    }, [isActive, resetHeader]);

    useEffect(() => {
        setUrl(window.location.origin);
        setUrlPathname(window.location.pathname);
        setUrlSearch(window.location.search);
    }, [setUrl, setUrlPathname, setUrlSearch]);

    return (
        <header
            className={styles[`mobile-header`]}
            ref={headerRef}
            {...props}
            data-header-type="mobile"
        >
            <nav aria-label="Main Navigation">
                <div
                    className={styles[`mobile-header__wrapper`]}
                    {...(isMenuOpen || searchBarOpen ? { inert: '' } : {})}
                >
                    <a
                        className={styles[`mobile-header__logo`]}
                        href={logoHref}
                        aria-label="Atlantic Health Systems Homepage"
                        onClick={handleLogoClick}
                        data-trigger={'a'}
                        csr={disableCSR ? !disableCSR : false}
                    >
                        <Image src={headerLogo} decorative />
                    </a>
                    <div className={styles[`mobile-header__actions`]}>
                        <Button
                            ariaLabel={searchAriaLabel}
                            buttonStyle={Button.STYLE.TERTIARY}
                            iconComponent={LazyIconSearch}
                            className={styles[`mobile-header__search`]}
                            ref={searchButtonRef}
                            onClick={ToggleSearchBar}
                        />
                        <Button
                            ariaLabel={mainNavAriaLabel}
                            buttonStyle={Button.STYLE.TERTIARY}
                            iconComponent={LazyIconMenu2}
                            className={styles[`mobile-header__menu`]}
                            onClick={OpenMenu}
                            ref={openMenuButtonRef}
                            aria-controls={mobileNavId}
                            aria-haspopup="true"
                            aria-expanded={isMenuOpen}
                        />
                    </div>
                </div>
                {/* Search Bar */}
                <form
                    className={classNames(
                        styles[`mobile-header__search-bar`],
                        searchBarOpen && styles[`mobile-header__search-bar--open`],
                    )}
                    ref={searchBarRef}
                    {...(!searchBarOpen ? { inert: '' } : {})}
                    onSubmit={submitForm}
                    data-trigger="search"
                >
                    <div className={styles[`mobile-header__search-bar-wrapper`]}>
                        <Typeahead
                            ref={searchInputRef}
                            label={searchLabelOverride}
                            buttonProps={{
                                type: 'submit',
                                iconComponent: LazyIconSearch,
                                ariaLabel: 'search',
                            }}
                            items={suggestions}
                            inputValue={searchValue}
                            onInputChange={handleSetSearchValue}
                            onSelectionItemChange={handleSelectSuggestion}
                            wrapperClassName={styles[`mobile-header__search-bar-input`]}
                            popoverClassName={styles[`mobile-header__search-bar-popover`]}
                        />
                        <Button
                            ariaLabel={closeSearchAriaLabel}
                            buttonStyle={Button.STYLE.TERTIARY}
                            iconComponent={LazyIconX}
                            className={styles[`mobile-header__close`]}
                            onClick={ToggleSearchBar}
                            type="button"
                        />
                    </div>
                </form>
                {/* Mobile Navigation */}
                <div
                    role="menu"
                    className={classNames(
                        styles[`mobile-header__nav-container`],
                        isMenuOpen && styles[`mobile-header__nav-container--open`],
                        styles[`mobile-header--bg-linen`],
                    )}
                    ref={mobileNavRef}
                    id={mobileNavId}
                >
                    <div className={styles[`mobile-header__nav`]}>
                        <div className={styles[`mobile-header--bg-white`]} ref={levelOneRef}>
                            <div
                                className={classNames(
                                    styles[`mobile-header__wrapper`],
                                    styles[`mobile-header__level-one`],
                                )}
                            >
                                <div className={styles[`mobile-header__logo`]}>
                                    <a
                                        href={logoHref}
                                        aria-label="Atlantic Health System Homepage"
                                        role="menuitem"
                                        onClick={handleLogoClick}
                                        data-trigger={'a'}
                                        csr={disableCSR ? !disableCSR : false}
                                    >
                                        <Image
                                            src={headerLogo}
                                            decorative
                                            className={styles[`mobile-header__logo--image`]}
                                        />
                                    </a>
                                </div>
                                <div className={styles[`mobile-header__actions`]}>
                                    <Button
                                        ariaLabel={searchAriaLabel}
                                        buttonStyle={Button.STYLE.TERTIARY}
                                        iconComponent={LazyIconSearch}
                                        className={styles[`mobile-header__search`]}
                                        role="menuitem"
                                        onClick={ToggleSearchBar}
                                        ref={menuSearchButtonRef}
                                    />
                                    <Button
                                        ariaLabel={closeNavAriaLabel}
                                        buttonStyle={Button.STYLE.TERTIARY}
                                        iconComponent={LazyIconX}
                                        className={styles[`mobile-header__close`]}
                                        ref={closeMenuButtonRef}
                                        onClick={() => CloseMenu()}
                                        aria-controls={mobileNavId}
                                        role="menuitem"
                                    />
                                </div>
                            </div>

                            {Array.isArray(navigationItems) &&
                                navigationItems?.map((item, index) => {
                                    return (
                                        <div
                                            className={classNames(
                                                styles[`mobile-header__level-one--button`],
                                            )}
                                            key={index + navigationItemsKey}
                                        >
                                            <Button
                                                role="menuitem"
                                                buttonStyle={Button.STYLE.MAIN_NAV}
                                                type="button"
                                                label={item?.parent}
                                                iconComponent={LazyIconChevronRight}
                                                iconRight
                                                aria-expanded={index === currentNavigationIndex}
                                                aria-haspopup="true"
                                                aria-controls={`second-level-` + index}
                                                onClick={(e) => OpenLevelTwo(e, index)}
                                            />
                                        </div>
                                    );
                                })}
                        </div>

                        {/* Primary CTA on Level One */}
                        {primaryCta && (
                            <div
                                className={classNames(
                                    styles[`mobile-header__cta`],
                                    styles[`mobile-header--bg-white`],
                                )}
                            >
                                <Button
                                    {...primaryCta}
                                    buttonStyle={Button.STYLE.PRIMARY}
                                    role="menuitem"
                                    analytics={{
                                        componentName: 'Navigation',
                                        componentTitle: 'navigation',
                                    }}
                                    csr={disableCSR ? !disableCSR : primaryCta?.csr}
                                />
                            </div>
                        )}

                        {/* Start Utility Nav */}
                        {Array.isArray(utilityNavigationItems) &&
                            utilityNavigationItems.length > 0 && (
                                <div className={styles[`mobile-header__utility-nav`]}>
                                    {utilityNavigationItems?.map((item, index) => {
                                        return (
                                            <div key={`language-dropdown-${index}`}>
                                                {item?.dropdown && !hideLanguageSelector && (
                                                    <div
                                                        className={
                                                            styles[
                                                                `mobile-header__dropdown-wrapper`
                                                            ]
                                                        }
                                                    >
                                                        <Button
                                                            key={index + utilityItemsKey}
                                                            type="button"
                                                            buttonStyle={Button.STYLE.UTILITY}
                                                            label={languageLabel}
                                                            aria-label={`Change site Language, currently ${languageLabel}`}
                                                            iconComponent={[
                                                                { iconName: 'globe' },
                                                                {
                                                                    iconName: 'chevron-down',
                                                                    iconProps: {
                                                                        className: classNames(
                                                                            styles[
                                                                                `mobile-header__chevron`
                                                                            ],
                                                                            languageDropdownOpen &&
                                                                                styles[
                                                                                    `mobile-header__chevron--open`
                                                                                ],
                                                                        ),
                                                                    },
                                                                },
                                                            ]}
                                                            id={`utility-toggle-${index}`}
                                                            aria-controls={`utility-dropdown-${index}`}
                                                            aria-haspopup="true"
                                                            aria-expanded={languageDropdownOpen}
                                                            onClick={ToggleDropdown}
                                                            role="menuitem"
                                                        />
                                                        <ul
                                                            ref={languageDropdownRef}
                                                            className={classNames(
                                                                styles[
                                                                    `mobile-header__utility-dropdown`
                                                                ],
                                                                languageDropdownOpen &&
                                                                    styles[
                                                                        `mobile-header__utility-dropdown--open`
                                                                    ],
                                                            )}
                                                            id={`utility-dropdown-${index}`}
                                                            role="menu"
                                                            aria-labelledby={`utility-toggle-${index}`}
                                                        >
                                                            {item.children.map((child, index) => {
                                                                const normalizedLabel = (label) => {
                                                                    return label
                                                                        .normalize('NFD')
                                                                        .replace(
                                                                            /[\u0300-\u036f]/g,
                                                                            '',
                                                                        )
                                                                        .toLowerCase();
                                                                };

                                                                const href = onLanguageChange
                                                                    ? undefined
                                                                    : child?.href +
                                                                      urlPathname +
                                                                      urlSearch;

                                                                const onClick = onLanguageChange
                                                                    ? (e) => {
                                                                          onLanguageChange(
                                                                              e,
                                                                              child?.label.toLowerCase(),
                                                                          );
                                                                          ToggleDropdown();
                                                                      }
                                                                    : undefined;

                                                                return (
                                                                    <li
                                                                        role="none"
                                                                        key={`language-select-${index}`}
                                                                    >
                                                                        <Button
                                                                            ref={(el) =>
                                                                                (languageRefs.current =
                                                                                    [
                                                                                        ...languageRefs.current,
                                                                                        el,
                                                                                    ])
                                                                            }
                                                                            key={
                                                                                index +
                                                                                utilityChildKey
                                                                            }
                                                                            href={href}
                                                                            onClick={onClick}
                                                                            buttonStyle={
                                                                                Button.STYLE.UTILITY
                                                                            }
                                                                            label={child?.label}
                                                                            active-language={url.indexOf(
                                                                                normalizedLabel(
                                                                                    child?.label,
                                                                                ),
                                                                            )}
                                                                            label-setter={
                                                                                child?.label
                                                                            }
                                                                            aria-label={`Change site language to ${child?.label}`}
                                                                            role="menuitem"
                                                                            className={
                                                                                styles[
                                                                                    `desktop-header__language-option`
                                                                                ]
                                                                            }
                                                                            csr={false}
                                                                            analytics={{
                                                                                componentName:
                                                                                    'Navigation',
                                                                                componentTitle:
                                                                                    'utility_nav',
                                                                                interactionType:
                                                                                    'dropdown',
                                                                            }}
                                                                        />
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                                {!item?.dropdown && (
                                                    <Button
                                                        key={index + utilityItemsKey}
                                                        href={item?.href}
                                                        buttonStyle={Button.STYLE.UTILITY}
                                                        iconName={item?.iconName}
                                                        label={item?.label}
                                                        aria-label={item?.label}
                                                        role="menuitem"
                                                        analytics={{
                                                            componentName: 'Navigation',
                                                            componentTitle: 'utility_nav',
                                                            interactionType: 'internal_link',
                                                        }}
                                                        csr={disableCSR ? !disableCSR : item?.csr}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                        {/* Start Child Pages */}
                        <div
                            className={classNames(
                                styles[`mobile-header__level-two`],
                                styles[`mobile-header--bg-white`],
                                levelTwoOpen &&
                                    currentNavigationIndex !== null &&
                                    styles[`mobile-header__level-two--open`],
                            )}
                            id={`second-level-` + currentNavigationIndex}
                            style={{ paddingBottom: `${scrollHeight}px` }}
                        >
                            <div
                                className={classNames(
                                    styles[`mobile-header__wrapper`],
                                    styles[`mobile-header--bg-white`],
                                )}
                                role="menu"
                            >
                                {/* Level 2 Controls */}
                                <Button
                                    buttonStyle={Button.STYLE.BREADCRUMB}
                                    iconComponent={LazyIconArrowLeft}
                                    label="Back"
                                    ariaLabel={backNavAriaLabel}
                                    className={styles[`mobile-header__back`]}
                                    onClick={CloseLevelTwo}
                                    ref={backButtonRef}
                                    role="menuitem"
                                />
                                <Button
                                    ariaLabel={closeNavAriaLabel}
                                    buttonStyle={Button.STYLE.TERTIARY}
                                    iconComponent={LazyIconX}
                                    className={styles[`mobile-header__close`]}
                                    onClick={() => CloseMenu()}
                                    aria-controls={mobileNavId}
                                    role="menuitem"
                                />
                            </div>
                            {/* Level 2 Content */}
                            <Text
                                variant={Text.VARIANT.E1}
                                tag={Text.TAG.P}
                                key={currentNavigationIndex}
                                content={levelTwoItems?.parent}
                                className={classNames(
                                    styles[`mobile-header__subheading`],
                                    styles[`mobile-header--bg-grey-2`],
                                    'type-e1',
                                )}
                            />
                            <div className={styles[`mobile-header__link-list`]}>
                                {Array.isArray(levelTwoItems?.children) &&
                                    levelTwoItems?.children?.map((child, index) => {
                                        return (
                                            <Button
                                                key={index + levelTwoItemKey}
                                                href={child?.href}
                                                className={classNames(
                                                    styles[`mobile-header__link`],
                                                )}
                                                buttonStyle={Button.STYLE.TERTIARY}
                                                label={child?.label}
                                                aria-label={child?.label}
                                                role="menuitem"
                                                alignment="left"
                                                analytics={{
                                                    componentName: 'Navigation',
                                                    componentTitle: parentNavItem?.textContent,
                                                    interactionType: 'navigation',
                                                }}
                                                csr={disableCSR ? !disableCSR : child?.csr}
                                                onClick={() => resetHeader()}
                                            />
                                        );
                                    })}
                            </div>
                            {/* Level 2 Cateogorized Content */}
                            {Array.isArray(levelTwoItems?.categorized) &&
                                levelTwoItems?.categorized?.map((catItem, index) => {
                                    return (
                                        <div
                                            className={classNames(
                                                styles[`mobile-header__categorized`],
                                                styles[`mobile-header--bg-white`],
                                            )}
                                            key={levelTwoItemKey + index}
                                        >
                                            <Text
                                                variant={Text.VARIANT.E1}
                                                tag={Text.TAG.P}
                                                content={catItem.label}
                                                className={classNames(
                                                    styles[`mobile-header__category-label`],
                                                )}
                                            />
                                            <div className={styles[`mobile-header__link-list`]}>
                                                {Array.isArray(catItem?.children) &&
                                                    catItem?.children?.map((child, index) => {
                                                        return (
                                                            <Button
                                                                key={child?.label + index + key}
                                                                href={child?.href}
                                                                className={classNames(
                                                                    styles[`mobile-header__link`],
                                                                )}
                                                                buttonStyle={Button.STYLE.TERTIARY}
                                                                label={child?.label}
                                                                aria-label={child?.label}
                                                                role="menuitem"
                                                                alignment="left"
                                                                analytics={{
                                                                    componentName: 'Navigation',
                                                                    componentTitle:
                                                                        parentNavItem?.textContent,
                                                                    interactionType: 'navigation',
                                                                }}
                                                                csr={
                                                                    disableCSR
                                                                        ? !disableCSR
                                                                        : child?.csr
                                                                }
                                                            />
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    );
                                })}
                            {/* Level 2 Feature card */}
                            {levelTwoItems?.menuCardComponent && (
                                <div
                                    ref={featureCardRef}
                                    className={styles[`mobile-header__feature-card`]}
                                >
                                    {/* menu card */}
                                    {levelTwoItems?.menuCardComponent}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

MobileHeader.propTypes = {
    ...headerPropTypes,
};

export default MobileHeader;
