import React from 'react';
import Header from '@/features/Header/Header';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';
import { HeaderMenuCard } from '@/design-system/molecules/HeaderMenuCard';
import HomepageAlert from '@/design-system/molecules/HomepageAlert/HomepageAlert';

const HeaderWrapper = (props) => {
    const getAlertProperties = (alert) => {
        let alertItems = alert?.globalAlertFragment?.[':items']?.root?.[':items'];
        let alertProperties = Object.keys(alertItems).filter((item) =>
            alertItems[item]?.[':type'].includes('homepage-alert-banner'),
        )[0];
        return alertItems[alertProperties];
    };
    if(props.showAlerts && props.globalAlerts && Array.isArray(props.globalAlerts)){
        let alertToRender = props.globalAlerts.filter(
            (alert) => alert?.renderOnSyndicatedHeader || (getAlertProperties(alert)?.isGlobal && getAlertProperties(alert)?.render)
        )[0];

        if(alertToRender){
            alertToRender.alertBannerComponent =  <HomepageAlert alertId={alertToRender?.localizedFragmentVariationPath} {...getAlertProperties(alertToRender)}></HomepageAlert>;
            props.alertBannerComponent = alertToRender.alertBannerComponent;
        }
    }

    props.navigationItems.forEach((item) => {
        const menuCardXF = item?.menuCardExperienceFragment?.[':items']?.root?.[':items']?.menu_card;

        if (menuCardXF) {
            const modifiers = generateStyleModifiers(menuCardXF?.appliedCssClassNames);
            const theme = modifiers?.theme;

            item.menuCardComponent = <HeaderMenuCard {...menuCardXF} theme={theme} disableCSR={true} />;
        }
    });

    return <Header {...props} disableCSR={true} />;
};

export default HeaderWrapper;
